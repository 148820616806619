import * as RailsJS from 'rails-ujs'
import * as Turbolinks from 'turbolinks'

import * as jQuery from 'jquery/dist/jquery'
import * as Popper from 'popper.js/dist/popper'
import 'bootstrap/dist/js/bootstrap'

import '../styles/main.scss'

(function() {
  RailsJS.start()
  Turbolinks.start()

  window.$ = jQuery
  window.jQuery = jQuery
  window.Popper = Popper
}).call(this)

if (!window.appInitialized) {
  // do runonce setup

  document.addEventListener('turbolinks:load', () => {
    const activeInstruction = document.getElementsByClassName('step-instructions active')[0]

    if (activeInstruction) {
      activeInstruction.scrollIntoView()
    }
  })

  window.appInitialized = true
}
